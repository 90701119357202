
<template>
  <div class="comp-accordion">
    <CompAccordionSingleEntry
      v-for="singleSubject in propSubjects"
      :propSubject="singleSubject"
      :propParentHeadlineForId="propParentHeadlineForId"
    />
  </div>
</template>

<script>
import CompAccordionSingleEntry from '../components/CompAccordionSingleEntry.vue'

export default {
  name: 'CompListAccordion',
  
  props: {
    propSubjects: {
      type: Array,
      required: true
    },
    
    propParentHeadlineForId: {
      type: String,
      required: true
    },
  },
  
  components: {
    CompAccordionSingleEntry
  },
}
</script>

<style lang="scss">
  .comp-accordion {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-headline-bodytext);
  }
</style>

