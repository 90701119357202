var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comp-button-copy-paste"},[_c('p',{staticClass:"label annotation"},[_vm._v(" "+_vm._s(_vm.propLabel)+" ")]),_c('div',{staticClass:"button-copy-paste"},[_c('a',{staticClass:"button-link link-button button-outline",attrs:{"href":_vm.computedHref,"aria-label":_vm.propAriaLabelLink}},[_vm._v(_vm._s(_vm.propText))]),_c('div',{staticClass:"button-copy-wrapper"},[_c('button',{class:{
          'button-copy' : true,
          'button-outline' : true,
          'animation-playing' : _vm.startButtonAnimation
        },attrs:{"aria-label":_vm.propAriaLabelCopy},on:{"click":_vm.copyContentToClipboard}},[_c('div',{class:{
            'icon-copy-paste' : true,
          }},[_c('div',{staticClass:"layer-front"}),_c('div',{staticClass:"layer-back"})])]),_c('transition',{attrs:{"name":"bubble-ups-up","mode":"in-out","appear":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttonClickedRecently),expression:"buttonClickedRecently"}],staticClass:"success_message bubble bubble-black bubble-ups"},[(_vm.propLabel === `E-Mail`)?_c('span',[_vm._v("E-Mailadresse in die Zwischenablage kopiert")]):(_vm.propLabel === `Telefon`)?_c('span',[_vm._v("Telefonnummer in die Zwischenablage kopiert")]):_vm._e()])])],1)]),(_vm.propDescription !== ``)?_c('p',{staticClass:"description annotation label",domProps:{"innerHTML":_vm._s(_vm.propDescription)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }