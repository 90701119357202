
<template>  
  <div class="comp-list-subject-with-text max-width">
    <div
      :key="subject.subject"
      v-for="subject in propSubjects"
      class="holder"
    >
      <div class="subject-and-description-holder">
        <div class="subject typography-editorial-small">
          {{ subject.subject }}
        </div>
        <div
          v-if="subject.descriptionRichtext && subject.descriptionRichtext.length > 0"
          class="description description-richtext"
        >
          <template
            v-for="(item, index) in subject.descriptionRichtext"
          >
            <div
              v-if="item.type === `text`"
              :key="`desciption-richtext-${index}`"
              class="desciption-richtext-entry desciption-richtext-text"
              :class="item.class"
            >
              <p
                v-for="(entry, index) in item.content"
                v-html="entry"
                class="text"
                :key="`desciption-richtext-item-${index}`"
              />
            </div>

            <div
              v-if="item.type === `job`"
              :key="`desciption-richtext-${index}`"
              class="desciption-richtext-entry desciption-richtext-job"
              :class="{
                'truncate' : item.truncate
              }"
            >
              <p
                class="name"
              ><b>{{ item.name }}</b></p>

              <p
                v-if="item.hours !== ``"
                class="hours"
              >({{ item.hours }})</p>

              <p
                v-if="item.teaser !== ``"
                class="teaser"
              >{{ item.teaser }}</p>

              <template v-if="!item.truncate">
                <p
                  v-for="(entry, index) in item.content"
                  v-html="entry"
                  class="text"
                  :key="`desciption-richtext-item-${index}`"
                />
              </template>

              <details v-else-if="item.truncate">
                <summary>{{ item.buttonReadMore }}</summary>
                <p
                  v-for="(entry, index) in item.content"
                  v-html="entry"
                  class="text"
                  :key="`desciption-richtext-${index}`"
                />
              </details>

              <summary></summary>
            </div>

            <ul
              v-else-if="item.type === `list-bullet`"
              class="desciption-richtext-entry desciption-richtext-list-bullet"
              :key="`desciption-richtext-${index}`"
            >
              <li
                v-for="entry in item.content"
                :key="entry"
                v-html="entry"
              />
            </ul>
          </template>
        </div>

        <div
          v-else
          class="description"
          v-html="subject.description"
        />
      </div>
      
      <div class="divider-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompListSubjectsWithText',
  
  props: {
    propSubjects: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .comp-list-subject-with-text {
    margin-top: 1em;
      > * + * {
        /* margin-top: 2em; */
      }
    
    .subject-and-description-holder {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--column-gap);
      overflow-x: scroll;

      width: calc(100% + var(--padding-outside) + var(--padding-outside));
      margin-left: calc(var(--padding-outside) * -1);
      // transform: translateX(-50%);
      // max-width: var(--max-width);
      padding-inline: var(--padding-outside);
      box-sizing: border-box;
      
      > * {
        padding-block: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 3.5rem);
        min-width: min(16rem, 65vmin);
      }
    }
    
    .subject {
      text-align: unset;
      margin-inline: unset;
      padding-inline-start: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
      hyphens: auto;
    }
    
    .description {
      margin-top: .25em;
      padding-inline: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
      text-wrap: initial !important;
      max-width: 38ch;
    }

    .description-richtext > * + * {
      margin-top: .75em;
    }

    .desciption-richtext-entry:has(.margin-top-big) {
      margin-top: 2em;
    }

    .desciption-richtext-job {

      & > * + * {
        margin-top: var(--spacer-em-half);
      }

      & + .desciption-richtext-job {
        position: relative;
        margin-top: 4em;

        &:before {
          content: "";
          position: absolute;
          top: -2.75em;
          height: var(--border-strenght);
          width: var(--length-line);
          background-color: var(--black);
          opacity: .6;
        }

        &.truncate {

          summary:marker {
            display: none;
          }
        }
      }
    }

    .desciption-richtext-list-bullet {
      display: inline-block;
      margin-bottom: unset;  
    }
  }
</style>