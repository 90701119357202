<template>
	<div class="page page-contact">
		<section>
			<CompHero
				class="section"
				:propTextHero="[ 'Kontakt' ]"
				propHeadlineLevel="h1"
				:propApplyAnimation="false"
			/>
		</section>
		
		<section data-section="contact-buttons">
			<div class="columns-grid">
				<CompButtonCopyContent
					propType="E-Mail"
					propLabel="E-Mail"
					propAriaLabelCopy="E-Mailadresse in die Zwischenablage kopieren"
					:propLink="this.$store.state.mail"
					:propText="this.$store.state.mail"
					propDescription="Hinterlasse uns gerne eine Nachricht, wir melden uns binnen 1 bis 2 Werktagen."
				/>
				
				<CompButtonCopyContent
					propType="Phone"
					propLabel="Telefon"
					propAriaLabelCopy="Telefonnummer in die Zwischenablage kopieren"
					:propLink="this.$store.state.phone.number"
					:propText="this.$store.state.phone.layout"
					propDescription="Dienstag — Freitag  (Werktags)<br>
					09:00 — 15:00 Uhr"
				/>

				<!-- <CompButtonCopyContent
					propType="Address"
					propLabel="Adresse"
					propAriaLabelCopy="Adresse in die Zwischenablage kopieren"
					:propLink="this.$store.state.phone.number"
					:propText="`Hermangasse 9/1, 1070 Wien`"
					propDescription="Dienstag — Freitag  (Werktags)<br>
					09:00 — 15:00 Uhr"
				/> -->
			</div>
		</section>
		
		<section
			class="containing-comp-list-subject-with-text"
			id="faq"
		>
			<CompHero
				class="section"
				propPreheadline="FAQ"
				:propTextHero="[ 'Antworten auf häufig gestellte Fragen' ]"
				propHeadlineLevel="h2"
				:propApplyAnimation="false"
			/>
			
			<CompListSubjectsWithText :propSubjects="subjects" />
		</section>
		
		<CompFooter />
	</div>
</template>

<script>
import CompHero from '../components/CompHero.vue'
import CompButtonCopyContent from '../components/CompButtonCopyContent.vue'
import CompListSubjectsWithText from '../components/CompListSubjectsWithText.vue'
import CompFooter from '../components/CompFooter.vue'
import CompServicePackage from '../components/CompServicePackage.vue'

import { createSEOMeta } from "@/helpers/helperMetainfo.js";

const metaInfos = {
	title: `Kontakt • Design Studio Eureka!`,
	description: `Kontaktiere das Design Studio Eureka! für Fragen oder um dein nächstes Projekt zu starten.`,
	image: ``,
	robots: `index, follow`
}

export default {
	name: 'PageContact',

    metaInfo: createSEOMeta(metaInfos.title, metaInfos.description, metaInfos.image, metaInfos.robots),

	components: {
		CompHero,
		CompButtonCopyContent,
		CompListSubjectsWithText,
		CompServicePackage,
		CompFooter
	},

	computed: {
		computedPhone () {
			return this.$store.state.phone
		},

		computedMail () {
			return this.$store.state.mail
		}
	},
	
	data () {
		return {
			subjects: [
				{
					subject: `Projektanfrage`,
					descriptionRichtext: [
						{
							type: `text`,
							tag: `p`,
							content: [
								`Wir freue uns sehr über das Interesse.`,
								`Schreibt uns ein Mail mit folgenden Infos an <a href="mailto:${this.$store.state.mail}">${this.$store.state.mail}<a>:`
							]
						},
						{
							type: `list-bullet`,
							tag: `ul`,
							content: [
								`Unternehmenswebseite <br><span class="annotation">(alternativ eine kurze Beschreibung des Unternehmens)</span>`,
								`Gewünschte Leistungen`,
								`Zeitrahmen`,
								`Budget`,
								`Kontaktinformationen`,
							]
						},
						{
							type: `text`,
							tag: `p`,
							content: [
								`Wir melden uns innerhalb von ein bis zwei Werktagen.`
							]
						},
					]
				},
				{
					subject: `Kostenschätzung`,
					descriptionRichtext: [
						{
							type: `text`,
							tag: `p`,
							content: [
								`
									Im Jahr 2022 haben wir neue Projekte ab einem Mindestbetrag von € 12.000,- angeboten.
								`,
								`
									Gerne legen wir ein detailliertes Angebot basierend auf einem umfangreichen Projektbrief.
								`
							]
						}
					]
				},
				{
					subject: `Öffnungszeiten`,
					descriptionRichtext: [
						{
							type: `text`,
							tag: `p`,
							content: [
								`Wir arbeiten dezentral und asynchron. Meistens erreichst du uns:`,
								`Dienstag — Freitag (Werktags)<br>
								09:00 — 15:00 Uhr`,
								`Solltest du uns nicht direkt erreichen können, hinterlasse uns gerne eine E-Mail oder Sprachnachricht. Wir melden uns innerhalb von ein bis zwei Werktagen."`
							]
						}
					]
				},
				{
					subject: `Unverbindlich plaudern`,
					descriptionRichtext: [
						{
							type: `list-bullet`,
							tag: `ul`,
							content: [
								`Bist du dir noch nicht sicher, ob du mit uns zusammenarbeiten möchtest?`,
								`Du hast Fragen?`,
								`Du hast Tipps?`,
								`Du brauchst Hilfe bei deinem Projekt?`,
								`Du willst dich einfach nur unterhalten?`
							]
						},
						{
							type: `text`,
							tag: `p`,
							content: [
								`Melde dich gerne per <a href="mailto:${this.$store.state.mail}">${this.$store.state.mail}</a>. Wir finden bestimmt eine Lösung.`,
							]
						},
					]
				},
				// {
				// 	subject: `Presse`,
				// 	description: `Die programmatische Umsetzung der Webseite inklusive Veröffentlichung. Das hier ist eine zweite Zeile.`
				// },
				{
					subject: `Praktikum`,
					description: `Aktuell haben wir keine Kapazitäten für Praktikant*innen.`
				},
				{
					subject: `Mitarbeiten`,
					descriptionRichtext: [
						// {
						// 	type: `job`,
						// 	name: `Geschäftspartner*innen`,
						// 	truncate: true,
						// 	hours: ``,
						// 	teaser: `Unsere Arbeit soll nur ein Teil eines erfüllten Lebens sein, deshlab suchen wir Geschäftspartner*innen.`,
						// 	buttonReadMore: `Ließ mehr`,
						// 	content: [
						// 		`
						// 			Arbeiten ist großartig und erfüllend. Wir haben das Privileg mit beeindruckenden Menschen zu kooperieren, und ihnen bei der Umsetzung ihrer Visionen zur Seite zu stehen.
						// 		`,
						// 		`
						// 			Unsere Arbeit soll ein Teil eines erfüllten Lebens sein, und mit den anderen Aspekten (Familie, Freizeit, Hobby, Körper, Geist, Reisen) harmonisch koexistieren.
						// 		`,
						// 		`
						// 			In der Praxis lässt sich das schwer Planen. Unterschiedliche Lebensbereiche lassen sich nicht klar voneinander treffen, und schwappen ineinander über.
						// 		`,
						// 		`
						// 			Um das meistern zu können, suchen wir nach Geschäftspartner*innen, die – sollten alle guten Pläne scheitern – auch bereit sind einzuspringen, mit der Gewissheit, dasselbe auch von uns zu erwarten zu können.
						// 		`,
						// 		`
						// 			Es ist unser Ziel, das Design Studio Eureka! mit hochqualifiziert, multitalentierten und selbstständigen agierenden Geschäftspartner*innen zu betreiben, die zu gleichen Teilen am Unternehmen beteiligt sind.
						// 		`,
						// 		`
						// 			Wenn du selbständig im Kreativbereich tätig bist und nach einer Partnerschaft suchst, können wir es kaum erwarte, dich kennen zu lernen. Schreibe uns doch erstmal ein <a href="mailto:${this.$store.state.mail}">Mail an ${this.$store.state.mail}</a>. Wir melden uns bald, um alles Weitere persönlich zu besprechen.
						// 		`,
						// 	]
						// },
						{
							type: `job`,
							name: `Überrasche uns`,
							truncate: false,
							hours: `12—20h`,
							teaser: ``,
							buttonReadMore: ``,
							content: [
								`
									<ul>
										<li>
											Du gehst durch die Welt, und siehst überall Möglichkeiten, Probleme zu lösen?
										</li>
										<li>
											Du möchtest etwas beweisen, eigenständig arbeiten und weißt, wann es richtig ist, deine Ideen durchzusetzen?
										</li>
										<li>
											Du verfügst über mindestens zwei hoch ausgeprägte Skills (z. B.: Fotografie + Programmieren)?
										</li>
									</ul>
								`,
								`
									Wow! Überrasche uns gerne mit deiner Bewerbung an <a href="mailto:${this.$store.state.mail}">${this.$store.state.mail}</a>. Wir melden uns bald und besprechen alles Weitere persönlich.
								`
							]
						}
					]
				}
			]
		}
	}
}

</script>

<style lang="scss">
	// #router-element.page-contact {
	// 	padding: unset;
		
	// 	section:not(.containing-comp-list-subject-with-text) {
	// 		padding-inline: var(--padding-outside);
	// 	}
		
	// 	section.containing-comp-list-subject-with-text {
	// 		max-width: var(--max-width);
	// 		margin-inline: auto;
	// 	}
	// }

	.columns-grid {
		max-width: var(--max-width);
		margin-inline: auto;
	}
	
	.page-contact {
		--scale-text-to-button-factor: 1.15;
		
		section[data-section="contact-buttons"] {
			margin-top: var(--spacer-padding);
			
			.columns-grid {
				grid-template-columns:
					repeat(
						auto-fit,
						minmax(min((26ch + 10vmin), 100%), 1fr)
					);
				/* row-gap: calc(var(--column-gap) * 2); */
			}
			
			.description {
				margin-top: inherit;
			}
		}
		
		// *:not(.footer) * {
		// 	text-wrap: balance;
		// }
	}
</style>
