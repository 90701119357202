
<template>
  <section
    :class="{
      'comp-service-package' : true,
      'package-section' : true,
      'coming-soon' : propService.isComingSoon
    }"
    :style="`--background-color: ${propService.background_color}`"
  >
    
    <p
      v-if="propService.isComingSoon"
      class="preheadline service-packages-divider-headline margin-bottom-small"
    >Coming Soon</p>

    <h2
      class="typography-editorial-medium"
      v-html="propService.headline"
    />
    
    <div
      v-html="propService.subheadline"
      class="subheadline-explanatory typography-editorial-small"
    />
      
    <template v-if="!propService.isComingSoon">
      <div
        v-for="singleServicePackage in propService.packages"
        class="service-packages"
        :key="singleServicePackage.sectionName"
      >
        <p
          v-if="singleServicePackage.sectionName !== ``"
          class="service-packages-divider-headline"
        >{{ singleServicePackage.sectionName }}</p>
        <CompAccordion
          :propSubjects="singleServicePackage.services"
          :propParentHeadlineForId="propService.services_package"
        />
      </div>
    </template>
    
    <div
      v-if="!propService.isComingSoon"
      class="inquires"
    >
      <!-- <p class="service-packages-divider-headline">Anfragen</p> -->
    
      <div class="inquiry-single">
        <h3 class="typography-editorial-small">{{propService.services_package}}-Projekt anfragen</h3>
        <p>Wir freuen uns sehr über dein Vertrauen! Gerne klären wir alle Details in einem persönlichen Gespräch. Beschreibe uns einfach dein Projekt, und wir melden uns bald bei dir zurück.</p>
        <a
          :href="`mailto:${this.$store.state.mai}`"
          class="link-button"
        >{{ this.$store.state.mail }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import CompAccordion from '../components/CompAccordion.vue'

export default {
  name: 'CompServicePackage',
  
  components: {
    CompAccordion
  },
  
  props: {
    propService: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/css/scss-variables.scss';
  
  .comp-service-package {

    .preheadline {
      margin-bottom: calc(var(--spacer-em-half) * 3);
    }
    
    .subheadline-explanatory {
      margin-top: var(--spacer-em-half);
    }
    
    .service-packages {
      margin-top: var(--spacer-block-with-fallback);
    }
    
    .service-packages-divider-headline {
      font-weight: 900;
      font-size: var(--font-size-headline-bodytext);
      opacity: var(--opacity-medium);
      text-align: center;
    }
    
    .inquires {
      text-align: center;
      margin-top: var(--spacer);
      
      display: grid;
      grid-template-columns: 1fr;
      gap: var(--spacer);
      
      p {
        hyphens: none;
        text-wrap: balance;
      }
      
      .service-packages-divider-headline {
        grid-column: 1 / -1;
      }
      
      .inquiry-single {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--spacer-em-half) * 2);

        .link-button {
          margin-top: calc(var(--spacer-em-half) * 2);
        }
        
        p:not([class]) {
          max-width: 40ch;
          /* text-wrap: balance; */
        }
      }
    }
  }
</style>